.customWrapper {
  font-size: 24px;
  background-color: rgb(0, 0, 0);
  justify-content: space-between;
  width: 100%;
}

.customCanvas {
  height: 500px;
  max-height: 500px;
  min-width: 500px;
  max-width: 500px;
  overflow: auto;
  background-color: black;
}

.customPrevBtn {
  cursor: pointer;
  display: inline-block;
  width: 50px;
  color: #ffff;
  background-color: rgb(0, 0, 0);
  border-radius: 6px;
  margin-right: 20px;
  padding-top: 5px;
  margin-bottom: 10px;
}
.customNextBtn {
  cursor: pointer;
  display: inline-block;
  width: 50px;
  color: #ffff;
  background-color: rgb(0, 0, 0);
  border-radius: 6px;
  margin-left: 20px;
  padding-top: 5px;
  margin-bottom: 10px;
}
.customPages {
  color: #ffff;
}

iframe {
  width: 100% !important;
}